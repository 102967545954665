<template>
  <div>
    <div v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Nom
          </th>
          <th class="text-left">
            Dernière connexion
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="user in users"
            :key="user.id"
        >
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td class="font-weight-bold">{{ user.last_login !== null ? formatDate(user.last_login) : "Jamais" }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>

import DateUtilMixin from "@/mixins/util/DateUtilMixin";

export default {
  name: 'LastLogin',
  mixins: [DateUtilMixin],
  async mounted() {
    this.users = await this.$store.dispatch("users/getUsersWithLastLogin");
    this.loading = false;
  },

  data() {
    return {
      loading: true,
      users: [],
    };
  },
  methods: {}

}
</script>