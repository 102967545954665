<template>
  <div>
    <v-container>
      <v-tabs v-model="tab">
        <v-tab v-if="isResponsableOrAdmin || hasPermission('view_config')">Général</v-tab>
        <v-tab>Sites</v-tab>
        <v-tab v-if="isResponsableOrAdmin || hasPermission('view_category')">Catégories</v-tab>
        <v-tab v-if="isResponsableOrAdmin || hasPermission('view_profile')">Utilisateurs</v-tab>
        <v-tab v-if="isResponsableOrAdmin || hasPermission('view_profile')">Dernières connexions</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="isResponsableOrAdmin || hasPermission('view_config')">
          <General/>
        </v-tab-item>
        <v-tab-item>
          <Sites/>
        </v-tab-item>
        <v-tab-item v-if="isResponsableOrAdmin || hasPermission('view_category')">
          <Categories/>
        </v-tab-item>
        <v-tab-item v-if="isResponsableOrAdmin || hasPermission('view_profile')">
          <Users @switch-drawer="switchDrawer" @edit-user="editUser"/>
        </v-tab-item>
        <v-tab-item v-if="isResponsableOrAdmin || hasPermission('view_profile')">
          <LastLogin/>
        </v-tab-item>

      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Sites from "@/views/Sites";
import Categories from "@/views/Categories";
import General from "@/components/admin/General";
import Users from "@/views/Users";
import NewIntervenantSidebar from "@/components/intervenants/NewIntervenantSidebar";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import LastLogin from "@/views/LastLogin";

export default {
  name: "Administration",
  components: {LastLogin, Users, General, Sites, Categories},
  mixins: [AuthMixin, DrawerMixin],
  props: {},
  mounted() {
    if (this.$store.getters["groups/groups"].length === 0) {
      this.$store.dispatch("groups/fetchGroups");
    }
    if(this.$route.query.tab === "user") {
      this.tab = 3;
    }
  },
  data() {
    return {
      tab: null,
      operation: "create",
      profileToUpdate: {},
      intervenant_id: 0,
      dialog: false
    };
  },
  computed: {
    selected_intervenant() {
      return this.$store.getters["profile/getSelectedProfile"];
    },
    groups_others() {
      return this.$store.getters["groups/groups_others"];
    }
  },
  methods: {
    editUser() {
      this.openDrawerDialog(NewIntervenantSidebar, {
        "createTitle": "Nouvel utilisateur",
        "updateTitle": "Mettre à jour l'utilisateur",
        "createButton": "Créer l'utilisateur",
        "groups": this.groups_others,
        "operation": "update",
        "key": this.selected_intervenant.id,
        intervenantToUpdate: this.selected_intervenant,
      });
    },
    switchDrawer() {
      this.openDrawerDialog(NewIntervenantSidebar, {
        "createTitle": "Nouvel utilisateur",
        "updateTitle": "Mettre à jour l'utilisateur",
        "createButton": "Créer l'utilisateur",
        "groups": this.groups_others,
        "operation": "create",
        "key": 0,
        "intervenantToUpdate": null,
      });
    },
  }
}
</script>

