<template>
  <v-data-iterator :items="responsables_techniques">
    <template v-slot:default="{ items }">
      <v-card @click="setUser(user)" outlined elevation="0" v-for="user in items"
              :key="user.id">
        <div class="pa-3">
          <v-row no-gutters class="pl-3 mb-2">
            <v-col class="pa-0 ma-0">
              <h3 class="secondary--text">{{ user.fullname }}</h3>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <div v-if="hasProfile(user) && user.profile.metiers !== null">
                <v-chip color="primary" v-for="metier in user.profile.metiers" :key="metier.id">{{
                    metier.name
                  }}
                </v-chip>
              </div>
            </v-col>
            <v-col cols="6">
              <p class="grey--text text--accent-2 pa-0 ma-0"
                 v-if="user.last_intervention_date !== null">Dernière intervention le
                {{ getFormattedDate(user.last_intervention_date) }}</p>
              <p class="grey--text text--accent-2 pa-0 ma-0" v-else>Pas de dernière intervention</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="6" class="pa-0 ma-0">
              <p class="grey--text pa-0 ma-0" v-if="hasProfile(user) && user.profile.tel_fixe !== null">
                {{ user.profile.tel_fixe }}</p>
              <p v-else class="grey--text pa-0 mb-0 mt-2">Pas de téléphone renseigné</p>
            </v-col>
            <v-col class="pa-0 ma-0">
              <p class="grey--text text--accent-2 pa-0 ma-0" v-if="user.last_intervention_note">Note dernière intervention
                : {{ user.last_intervention_note }} / 5</p>
              <p class="grey--text text--accent-2 pa-0 ma-0" v-else>Pas de note pour la dernière intervention</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="6">
              <p class="primary--text pa-0 ma-0" v-if="user.note_globale !== null">Note globale : {{ user.note_globale }}</p>
              <p class="primary--text pa-0 ma-0" v-else>Note globale : Non noté</p>
            </v-col>
            <v-col cols="6">
              <p class="grey--text text--accent-2 pa-0 ma-0" v-if="user.nombre_interventions > 0">{{ user.nombre_interventions }} interventions</p>
              <p class="grey--text text--accent-2 pa-0 ma-0" v-else>{{ user.nombre_interventions }} intervention</p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
  </v-data-iterator>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";

export default {
  name: "RPList",
  mixins: [DateUtilMixin],
  mounted() {
  },
  computed: {
    responsables_techniques() {
      return this.$store.getters["profile/getFilteredResponsables"];
    }
  },
  methods: {
    setUser(user) {
      this.$store.commit("profile/setSelectedProfile", user);
      this.$vuetify.goTo(0);
    },
    hasProfile(user) {
      return user.profile !== null;
    },
    hasPrestataire(user) {
      return this.hasProfile(user) && user.profile.prestataire !== null;
    },
  },
}
</script>

<style scoped>

</style>