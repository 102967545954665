const PERMISSIONS_NAMES = {
    "add_logentry": "Créer un log",
    "change_logentry": "Modifier un log",
    "delete_logentry": "Supprimer un log",
    "view_logentry": "Voir les logs",
    "add_asset": "Créer un équipement",
    "change_asset": "Modifier un équipement",
    "delete_asset": "Supprimer un équipement",
    "view_asset": "Voir les équipements",
    "add_group": "Créer un groupe",
    "change_group": "Modifier un groupe",
    "delete_group": "Supprimer un groupe",
    "view_group": "Voir les groupes",
    "add_permission": "Créer une permission",
    "change_permission": "Modifier une permission",
    "delete_permission": "Supprimer une permission",
    "view_permission": "Voir les permissions",
    "add_user": "Créer un utilisateur",
    "change_user": "Modifier un utilisateur",
    "delete_user": "Supprimer un utilisateur",
    "view_user": "Voir les utilisateurs",
    "add_config": "Créer une configuration",
    "change_config": "Modifier une configuration",
    "delete_config": "Supprimer une configuration",
    "view_config": "Voir les configurations",
    "add_contenttype": "Créer un type de contenu",
    "change_contenttype": "Modifier un type de contenu",
    "delete_contenttype": "Supprimer un type de contenu",
    "view_contenttype": "Voir les types de contenu",
    "add_contract": "Créer un contrat",
    "change_contract": "Modifier un contrat",
    "delete_contract": "Supprimer un contrat",
    "view_contract": "Voir les contrats",
    "add_category": "Créer un catégorie",
    "change_category": "Modifier une catégorie",
    "delete_category": "Supprimer une catégorie",
    "view_category": "Voir les catégories",
    "add_resetpasswordtoken": "Créer un jeton de mot de passe",
    "change_resetpasswordtoken": "Modifier un jeton de mot de passe",
    "delete_resetpasswordtoken": "Supprimer un jeton de mot de passe",
    "view_resetpasswordtoken": "Voir les jetons de mot de passe",
    "add_entry": "Créer un entrée financière",
    "change_entry": "Modifier une entrée financière",
    "delete_entry": "Supprimer une entrée financière",
    "view_entry": "Voir les entrées financières",
    "add_intervention": "Créer une intervention",
    "change_intervention": "Modifier une intervention",
    "delete_intervention": "Supprimer une intervention",
    "view_intervention": "Voir les interventions",
    "can_note": "Peut noter l'intervention",
    "add_session": "Créer une session",
    "change_session": "Modifier une session",
    "delete_session": "Supprimer une session",
    "view_session": "Voir les sessions",
    "add_site": "Créer un site",
    "change_site": "Modifier un site",
    "delete_site": "Supprimer un site",
    "view_site": "Voir les sites",
    "add_commentaire": "Créer un commentaire",
    "change_commentaire": "Modifier un commentaire",
    "delete_commentaire": "Supprimer un commentaire",
    "view_commentaire": "Voir les commentaires",
    "add_piecejointe": "Créer une pièce jointe",
    "change_piecejointe": "Modifier une pièce jointe",
    "delete_piecejointe": "Supprimer une pièce jointe",
    "view_piecejointe": "Voir les pièces jointes",
    "add_ticket": "Créer un ticket",
    "change_ticket": "Modifier un ticket",
    "delete_ticket": "Supprimer un ticket",
    "view_own_tickets": "Voir ses propres tickets",
    "view_ticket": "Voir tout les tickets",
    "can_self_assign": "Peut s'assigner les tickets",
    "can_assign": "Peut assigner des tickets",
    "add_prestataire": "Créer un prestataire",
    "change_prestataire": "Modifier un prestataire",
    "delete_prestataire": "Supprimer un prestataire",
    "view_prestataire": "Voir les prestataires",
    "add_profile": "Créer un profil",
    "change_profile": "Modifier un profil",
    "delete_profile": "Supprimer un profil",
    "view_profile": "Voir les profils",
    "add_periode": "Créer un période",
    "change_periode": "Modifier un période",
    "delete_periode": "Supprimer un période",
    "view_periode": "Voir les périodes",
    "add_address": "Créer une adresse",
    "change_address": "Modifier une adresse",
    "delete_address": "Supprimer une adresse",
    "view_address": "Voir les adresses",
    "add_contact": "Créer un contact",
    "change_contact": "Modifier un contact",
    "delete_contact": "Supprimer un contact",
    "view_contact": "Voir les contacts",
    "add_vendor": "Créer un magasin",
    "change_vendor": "Modifier un magasin",
    "delete_vendor": "Supprimer un magasin",
    "view_vendor": "Voir les magasins",
    "add_help": "Créer une demande d'aide",
    "change_help": "Modifier une demande d'aide",
    "delete_help": "Supprimer une demande d'aide",
    "view_help": "Voir les demandes d'aide",
    "add_plan": "Créer un plan",
    "change_plan": "Modifier un plan",
    "delete_plan": "Supprimer un plan",
    "view_plan": "Voir les plans",
    "add_emplacement": "Créer un emplacement",
    "change_emplacement": "Modifier un emplacement",
    "delete_emplacement": "Supprimer un emplacement",
    "view_emplacement": "Voir les emplacements",

}

const TYPE_NAMES = {
    "entry": "Entrée financière",
    "config": "configuration",
    "contract": "contrats",
    'profile': 'profil',
    'help': "Demande d'aide"
}

export const DEFAULT_PERMISSIONS = {
    "Prestataire": ["view_ticket", "view_intervention", "change_intervention"],
    "Demandeur": ["view_ticket", "create_ticket", "view_intervention"],
    "Collaborateur": [
        "view_ticket",
        "view_own_tickets",
        "create_ticket",
        "change_ticket",
        "view_intervention",
        "change_intervention",
        "create_intervention",
        "view_asset",
        "view_entry",
        "view_site",
    ],
    "Administrateur": Object.keys(PERMISSIONS_NAMES),
    "Responsable technique": Object.keys(PERMISSIONS_NAMES),
}

export function getPermissionName(codename) {
    if(codename in PERMISSIONS_NAMES) {
        return PERMISSIONS_NAMES[codename];
    } else {
        return "Permission inconnu";
    }
}
export function getTypeName(codename) {
    if(codename in TYPE_NAMES) {
        return TYPE_NAMES[codename];
    } else {
        return codename;
    }
}
