<template>
<v-card>
  <v-toolbar color="primary">
    <v-toolbar-title class="white--text">Modifier la catégorie</v-toolbar-title>
  </v-toolbar>
  <v-form class="pa-4">
    <v-text-field v-model="categoryModel.name" label="Nom"></v-text-field>
    <v-select v-model="categoryModel.type" :items="types" item-value="type" item-text="name"></v-select>
  </v-form>
  <v-card-actions>
    <v-btn text @click="patchCategory" color="primary">Modifier la catégorie</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
let type_select = [{
        name: "Domaine",
        type: "domaine"
      },
        {
          name: "Métier",
          type: "metier"
        }
      ]
export default {
  name: "EditCategory",
  props: {
    category: {
      type: Object,
    }
  },
  data() {
    let categoryModel = JSON.parse(JSON.stringify(this.category))
    return {
      types: type_select,
      categoryModel,
    };
  },
  methods: {
    async patchCategory() {
      let result = await this.$store.dispatch("categories/patchCategory", this.categoryModel);
      if(result === "success") {
        this.$store.dispatch("drawer/closeDialog");
        this.$store.dispatch("messages/announceSuccess", "Catégorie modifié.");
      }
    }
  }
}
</script>

<style scoped>

</style>