<template>
  <v-card class="pa-2" :loading="loading">
    <v-row>
      <v-col xl="4" lg="6" md="8" sm="10" cols="12">
        <h4 class="text--primary">Génerer les code-barres</h4>
        <v-autocomplete label="Site" :items="sites" v-model="site" item-text="name" item-value="id"></v-autocomplete>
        <v-btn class="mt-1" @click="generateBarcodes" color="primary">Générer</v-btn>
        <v-divider class="my-2"></v-divider>
        <h4 class="text--primary">Générer les code-barres selon la sélection</h4>
        <v-row justify="space-between">
          <span class="mt-7 mx-1"><v-icon>mdi-filter</v-icon>Filtrer par</span>
          <SiteTreeSelector v-model="assets_site_tree"/>
        </v-row>
        <v-autocomplete multiple
                        :no-data-text="loading_assets ? 'Chargement...' : assets_site_tree.length === 0 ? 'Veuillez sélectionner les sites à afficher' : 'Aucun équipement trouvé'"
                        label="Équipements"
                        :items="assets"
                        v-model="assets_select"
                        item-text="balise" item-value="id" chips small-chips deletable-chips></v-autocomplete>
        <v-btn class="mt-1" @click="generateBarcodesByAsset" color="primary">Générer</v-btn>
        <v-btn class="mt-1" @click="generateDataMatrixByAsset" color="primary">Générer en data matrix</v-btn>
        <v-btn class="mt-1 ml-2" @click="addAllAssets" color="secondary">Sélectionner tout</v-btn>
        <v-divider class="my-2"></v-divider>
        <v-btn color="primary" class="white--text" @click="generateToLabelBarcodes">Générer tout les équipements à imprimer</v-btn>
      </v-col>
      <v-col xl="4" lg="6" sm="12" cols="12" class="mt-2"
             v-if="isResponsableOrAdmin || (hasPermission('change_site') && hasPermission('add_site'))">
        <h4>Sites</h4>

        <v-radio-group v-model="parent">
          <v-treeview
              item-key="id"
              item-text="name"
              item-children="sites"
              :items="site_tree_view">
            <template v-slot:append="{item}">
              <v-row>
                <v-radio class="mt-2" :value="item.id"></v-radio>
                <v-btn text class="pa-0" @click="editSite(item)">
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>
                <v-btn text class="pa-0" @click="deleteSite(item)">
                  <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-treeview>
        </v-radio-group>
        <h4>Nouveau site</h4>
        <v-autocomplete
            hint="Cochez le site parent dans la site ci-dessus." persistent-hint
            :items="site_edit_select" v-model="parent" disabled item-value="id" item-text="fullname"
            label="Site parent"></v-autocomplete>
        <v-text-field :rules="[notNullNorEmpty]" label="Nom du site" v-model="name"></v-text-field>
        <v-btn color="primary" @click="createSite">Créer</v-btn>
      </v-col>
    </v-row>
    <v-dialog width="600" v-model="site_edit_dialog">
      <v-card elevation="0" width="600">
        <v-app-bar color="primary">
          <v-toolbar-title class="white--text">Modifier le site</v-toolbar-title>
        </v-app-bar>
        <v-form class="pa-4">
          <v-text-field v-model="site_edit_name" label="Nom"></v-text-field>
          <v-select :rules="[noLoopingSite]" item-value="id" item-text="fullname" :items="all_sites_select"
                    v-model="site_edit_parent" label="Site parent"></v-select>
        </v-form>
        <v-card-actions>
          <v-btn text color="primary" @click="comfirmSiteEdit">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: "Sites",
  components: {SiteTreeSelector},
  mixins: [AuthMixin],
  data() {
    return {
      loading: false,
      site: null,
      parent: null,
      name: null,
      assets_select: null,
      loading_assets: true,
      site_edit_id: null,
      site_edit_name: null,
      site_edit_parent: null,
      site_edit_dialog: false,
    }
  },
  async mounted() {
    if (this.$store.getters["sites/allSites"].length === 0) {
      await this.$store.dispatch("sites/fetchSites");
    }

    if (this.$store.getters["assets/allAssets"].length === 0) {
      await this.$store.dispatch("assets/fetchAssets");
      this.loading_assets = false;
    } else {
      this.loading_assets = false;
    }
  },
  computed: {
    sites() {
      return [{id: null, name: "Tous les sites"}, ...this.$store.getters["sites/fullLocationSites"]];
    },
    allSites() {
      let that = this;
      let sites = [...this.$store.getters["sites/allSites"]]
          .map(item => ({...item, fullname: that.getFullname(item)}));

      if (this.parent !== null) {
        let parentSite = sites.find(item => this.parent === item.id)
        return sites.filter(item => parentSite.name === item.parent || parentSite.name === item.top || parentSite.name === item.name);
      } else {
        return sites;
      }
    },
    site_edit_select() {
      let that = this;
      let sites = [...this.$store.getters["sites/allSites"]]
          .map(item => ({...item, fullname: that.getFullname(item)}));
      return sites;
    },
    all_sites_select() {
      return [{id: null, fullname: "Site principal (pas de site parent)"}, ...this.allSites]
    },
    topSites() {
      let that = this;
      return [{
        id: null,
        fullname: "Tous les sites"
      }, ...this.$store.getters["sites/allSites"].filter(site => site.top === null)
          .map(item => ({...item, fullname: that.getFullname(item)}))]
    },
    assets() {
      return [...this.$store.getters["assets/getFilteredAssets"]];
    },
    site_tree_view() {
      return this.$store.getters["sites/sitesTree"]
    },
    assets_site_tree: {
      get() {
        return this.$store.getters["assets/getFilterSiteTree"];
      },
      set(value) {
        return this.$store.commit("assets/setFilterSiteTree", value)
      }
    },
    edit_site() {
      return this.sites.find(site => this.site_edit_id === site.id);
    }
  },
  methods: {
    async generateBarcodes() {
      this.loading = true;
      try {
        let url = await this.$store.dispatch("sites/generateBarcodes", {site: this.site});
        if (url !== null) {
          var link = document.createElement("a");
          link.download = name;
          link.href = url;
          link.target = "_blank";
          link.click();
        } else {
          this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
        }
      } catch (e) {
        this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
      }
      this.loading = false;
    },
    async generateBarcodesByAsset() {
      this.loading = true;
      try {
        let url = await this.$store.dispatch("sites/generateBarcodesByAsset", {assets: this.assets_select, mode:"barcode"});
        if (url !== null) {
          var link = document.createElement("a");
          link.download = name;
          link.href = url;
          link.target = "_blank";
          link.click();
        } else {
          this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
        }
      } catch (e) {
        this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
      }
      this.loading = false;
    },
    async generateDataMatrixByAsset() {
      this.loading = true;
      try {
        let url = await this.$store.dispatch("sites/generateBarcodesByAsset", {assets: this.assets_select, mode: "datamatrix"});
        if (url !== null) {
          var link = document.createElement("a");
          link.download = name;
          link.href = url;
          link.target = "_blank";
          link.click();
        } else {
          this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
        }
      } catch (e) {
        this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
      }
      this.loading = false;
    },
    async generateToLabelBarcodes() {
      this.loading = true;
      try {
        let url = await this.$store.dispatch("sites/generateToLabelBarcodes");
        if (url !== null) {
          var link = document.createElement("a");
          link.download = name;
          link.href = url;
          link.target = "_blank";
          link.click();
        } else {
          this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
        }
      } catch (e) {
        this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
      }
      this.loading = false;
    },
    addAllAssets() {
      this.assets_select = this.assets.map((asset) => asset.id);
    },
    getFullname(site) {
      let siteObject = site;
      let siteText = siteObject.name;
      if (siteObject.parent !== null) {
        siteText += `, ${siteObject.parent}`;
      }
      if (siteObject.top !== null) {
        siteText += `, ${siteObject.top}`;
      }
      return siteText;
    },
    async deleteSite(site) {
      try {
        await this.$store.dispatch("sites/deleteSite", site);
        await this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Site supprimé."});
        await this.$store.dispatch("sites/fetchSites");
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
      }
    },
    async createSite() {
      if (this.name === null) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Certains champs ne sont pas remplis."
        });
        return;
      }
      let site = {
        name: this.name,
        parent: this.parent,
      }
      try {
        await this.$store.dispatch("sites/postSite", site);
        await this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Le site a bien été créé."});
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {"type": "error", "message": "Une erreur est survenue."});
      }
    },
    editSite(site) {
      this.site_edit_id = site.id;
      this.site_edit_name = site.name;
      this.site_edit_parent = this.sites.find((search_site) => site.parent === search_site.name)?.id;
      this.site_edit_dialog = true;
    },
    async comfirmSiteEdit() {
      await this.$store.dispatch("sites/patchSite",
          {
            name: this.site_edit_name,
            parent: this.site_edit_parent,
            id: this.site_edit_id,
          });
      this.site_edit_id = null;
      this.site_edit_name = null;
      this.site_edit_parent = null;
      this.site_edit_dialog = false;
    },
    noLoopingSite(value) {
      let sites = this.$store.getters["sites/allSites"];
      let chosen_site = sites.find(site => value === site.id);
      if (chosen_site) {
        return this.edit_site.name !== chosen_site.parent && this.edit_site.name !== chosen_site.top || "Site enfant déjà dans l'arborescence";
      } else {
        return true;
      }
    },
    notNullNorEmpty(value) {
      return value !== null && value !== "" || "Ce champ n'a pas été rempli.";
    }
  },

}
</script>

<style scoped>

</style>