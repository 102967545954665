<template>
  <v-dialog v-model="permissionDialog" max-width="800">
    <template v-slot:activator="{on, attrs}">
      <v-btn class="px-2" v-on="on" v-bind="attrs" color="primary">Modifier les droits</v-btn>
    </template>
    <v-card v-if="userId !== null">
      <v-app-bar flat dark color="primary">
        <v-toolbar-title>Permissions utilisateur</v-toolbar-title>
      </v-app-bar>
      <v-card-text class="pt-2">
        <span class="orange--text">Fonctionnalité encore expérimentale, pourrait ne pas fonctionner comme prévu.</span>
        <v-form ref="permissionForm" class="px-3">
          <v-row no-gutters v-for="(permissionList, field) in permissionsByType" :key="field">
            <v-col cols="12">
              <h4>{{ capitalizeFirstLetter(getTypeName(field)) }}</h4>
              <p v-if="field === 'prestataire'">
                Les prestataires étant aussi des utilisateurs, vous devez aussi donner les droits "Profil"
                correspondants pour autoriser la modification ou la création des prestataires.
              </p>
            </v-col>
            <v-col cols="3" v-for="(permission, index) in permissionList" :key="index">
              <v-checkbox v-model="permissions" :value="permission.id" multiple v-if="!hasDefaultPermission(permission)"
                          :label="getPermissionName(permission.codename)"></v-checkbox>
              <v-checkbox v-model="alwaystrue" disabled v-else
                          :label="getPermissionName(permission.codename)"></v-checkbox>

            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="updatePermissions">
          Mettre à jour les droits
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else class="red--text">
      Impossible de trouver l'utilisateur correspondant.
    </v-card>
  </v-dialog>
</template>
<script>
import {DEFAULT_PERMISSIONS, getPermissionName, getTypeName} from "@/util/permissions";
import {capitalizeFirstLetter, groupBy} from "@/util/core";

export default {
  name: 'UserPermissions',
  props: {userId: Number},
  async mounted() {
    let that = this;
    await this.$store.dispatch("permissions/fetchPermissions");
    await this.$store.dispatch("permissions/fetchUsersPermissions", {id: this.userId})
        .then(function () {
          let user = that.$store.getters["permissions/getSelectedUserPermissions"];
          that.permissions = user?.user_permissions.map(perm => perm.id);
        })
  },
  data() {
    return {
      permissions: [],
      permissionDialog: false,
      alwaystrue: true,
    }
  },
  computed: {
    allPermissions() {
      return this.$store.getters["permissions/getPermissions"];
    },
    permissionsByType() {
      return groupBy(this.$store.getters["permissions/getPermissions"], "type");
    },
    userWithPermissions() {
      return this.$store.getters["permissions/getSelectedUserPermissions"];
    },
    user_group() {
      return this.userWithPermissions?.group;
    },
    default_permissions() {
      return DEFAULT_PERMISSIONS;
    },
  },
  methods: {
    getPermissionName(codename) {
      return getPermissionName(codename);
    },
    getTypeName(codename) {
      return getTypeName(codename);
    },
    capitalizeFirstLetter(value) {
      return capitalizeFirstLetter(value);
    },
    hasDefaultPermission(permission) {
      if(!Object.keys(this.default_permissions).includes(this.user_group)) {
        return false;
      }
      let has_permission = Array.from(this.default_permissions[this.user_group]).includes(permission.codename);
      console.log(`${permission.codename} - ${this.user_group} - ${has_permission}`);

      return has_permission;
    },
    async updatePermissions() {
      try {
        this.$store.dispatch("permissions/udpdateUsersPermissions", {
          "id": this.userWithPermissions.id,
          "user_permissions": this.permissions,
        });
        this.$store.dispatch("messages/announceSuccess", "L'opération a réussi.");
      } catch (e) {
        this.$store.dispatch("messages/announceError", "Une erreur est survenue.");
      }
      this.permissionDialog = false;

    },
  }
}
</script>
